import { useEffect } from 'react'
import { IconLogin, IconLogout } from '@tabler/icons-react'
import { jwtDecode } from 'jwt-decode'

import { useLocalStorage } from '@/common'

export default function ButtonAuth ({ domain, className = '', clientID }) {
  const [splitcube] = useLocalStorage('splitcube', false)
  const [token, setToken] = useLocalStorage('token', false)
  const [user, setUser] = useLocalStorage('user', false)

  // parse user form token
  if (token && !user) {
    setUser(jwtDecode(token))
  }

  // if the token expires, logout
  useEffect(() => {
    if (user) {
      if ((user.exp * 1000) <= Date.now()) {
        setToken(false)
        setUser(false)
      }
      const i = setTimeout(() => {
        setToken(false)
        setUser(false)
      }, (user.exp * 1000) - Date.now())
      return () => clearTimeout(i)
    }
  }, [])

  // if they came from splitcube, just show nothing
  if (splitcube) {
    return null
  }

  function handleClick () {
    if (token) {
      setToken(false)
      setUser(false)
      document.location = `https://${domain}/logout?client_id=${clientID}&returnTo=${encodeURIComponent(`${document.location.origin}`)}`
    } else {
      document.location = `https://${domain}/authorize?client_id=${clientID}&scope=openid%20profile%20email&response_type=token%20id_token&nonce=${Date.now()}&redirect_uri=${encodeURIComponent(`${document.location.origin}/signed-in.html`)}`
    }
  }

  return (
    <button className={`btn ${className}`} onClick={handleClick} title={token ? 'Logout of creative-tools' : 'Login to creative-tools'}>
      {token
        ? <><IconLogout className='mr-1' /> Logout</>
        : <><IconLogin className='mr-1' /> Login</>}
    </button>
  )
}

import ButtonAuth from '@/ButtonAuth'
import { useUser, AUTH0_CLIENT_DOMAIN, AUTH0_CLIENT_ID } from '@/common'

function App ({ children, actionButtons }) {
  const [user] = useUser()

  return (
    <div className='h-full w-full flex bg-base-100 '>
      <div className='p-4 flex gap-2 items-center justify-between fixed w-full left-0 top-0 z-10 h-20 bg-neutral border-b border-neutral-500/20'>
        <div className='text-xl flex gap-2 items-center'>
          <img src='/favicon.ico' alt='' /> Gummicube Desktop ASO
        </div>
        <div className='flex gap-2 '>
          <ButtonAuth domain={AUTH0_CLIENT_DOMAIN} clientID={AUTH0_CLIENT_ID} className='btn-sm text-sm' />
          {actionButtons}
        </div>
      </div>

      <div className='mt-20 p-4 w-full'>
        {!user && 'Please use the login button above, to login.'}
        {!!user && !children && (
          <>
            <p>Make sure to set children prop. Here is your current user</p>
            <pre>
              {JSON.stringify(user, null, 2)}
            </pre>
          </>
        )}
        {!!user && children}
      </div>
    </div>
  )
}

export default App

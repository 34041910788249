import { useRef, useState } from 'react'
import { IconExclamationCircle, IconTrash } from '@tabler/icons-react'

import { api } from '@/common'

export default function ButtonAppDelete ({ app, className = '', children = <IconTrash />, onSubmit }) {
  const r = useRef()
  const [error, setError] = useState()
  const [disabled, setDisabled] = useState(false)

  const handleOpenClick = () => {
    setError('')
    setDisabled(false)
    r.current.showModal()
  }

  const handleOk = e => {
    setDisabled(true)
    e.preventDefault()
    api.delete(`/app/${app.id}`)
      .then(a => {
        r.current.close()
        setError(undefined)
        onSubmit()
      }).catch(e => {
        setError(e.message)
        setDisabled(false)
      })
  }

  return (
    <>
      <dialog ref={r} className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Remove {app.title}</h3>
          {error && (
            <div role='alert' className='alert alert-error flex flex-row my-2'>
              <IconExclamationCircle />
              <span>{error}</span>
            </div>
          )}

          <div className='my-4'>
            Are you sure you want to remove {app.title} from rank-collection?
          </div>

          <form method='dialog' className='modal-action gap-2'>
            <button className='btn btn-sm btn-ghost'>No</button>
            <button className='btn btn-sm btn-error' onClick={handleOk} disabled={disabled}><IconTrash /> {disabled ? <span className='loading loading-spinner' /> : 'Yes'}</button>
          </form>
        </div>
      </dialog>
      <button onClick={handleOpenClick} title={`Remove ${app.title}`} className={`btn ${className}`}>{children}</button>
    </>
  )
}

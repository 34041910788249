import { IconReportAnalytics } from '@tabler/icons-react'
import { api, download } from './common.js'

export default function ButtonAppReport ({ app, className, children = '', onSubmit }) {
  const handleClick = e => {
    api.fetchText(`/report/${app?.id || 'all'}`)
      .then(t => {
        const fname = (app && app !== 'all') ? `aso_report-${app.store_id}.csv` : 'aso_report.csv'
        download(fname, t)
      })
  }

  return (
    <>
      <button title={app?.title ? `Get a ranking-report for ${app.title}` : 'Get report for all apps'} onClick={handleClick} className={`btn ${className}`}><IconReportAnalytics /> {children}</button>
    </>
  )
}

/* global  FileReader */

import { useRef, useState } from 'react'
import { IconPlus, IconExclamationCircle } from '@tabler/icons-react'

import { api } from './common.js'

export default function ButtonTermAdd ({ apps, onSubmit, className = '', children = 'Add Terms to All', title = 'Add terms to all apps' }) {
  const r = useRef()
  const [value, setValue] = useState([])
  const [oneterm, setOneTerm] = useState('')
  const [error, setError] = useState()
  const [disabled, setDisabled] = useState(false)

  const handleOk = async e => {
    e.preventDefault()

    // they didn't press +, but there is a term
    if (oneterm) {
      value.push(oneterm)
    }

    try {
      setDisabled(true)
      await Promise.all(apps.map(async app => {
        await api.post(`/terms/${app.id}`, { terms: value })
        setDisabled(false)
        r.current.close()
        onSubmit(value)
      }))
    } catch (e) {
      setError(e.message)
      setDisabled(false)
    }
  }

  const handleFileChange = e => {
    if (e?.target?.files?.length) {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        setValue(result.split('\n').map(l => l.trim()).filter(l => l))
      }
      reader.readAsText(e.target.files[0], 'UTF-8')
    } else {
      console.error('No files!')
    }
  }

  const handleOpenClick = () => {
    setError('')
    setValue([])
    setOneTerm('')
    setDisabled(false)
    r.current.showModal()
  }

  const handleAddTerm = e => {
    setValue(v => [...v, ...oneterm.split(',').map(w => w.trim()).filter(w => w)])
    setOneTerm('')
  }

  return (
    <>
      <dialog ref={r} className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>{title}</h3>
          {error && (
            <div role='alert' className='alert alert-error flex flex-row my-2'>
              <IconExclamationCircle />
              <span>{error}</span>
            </div>
          )}

          <div className='flex flex-row gap-2 my-4'>
            {value.map((term, i) => (<div key={i} className='badge badge-secondary badge-outline'>{term}</div>))}
          </div>

          <div className='form-control w-full my-4'>
            <div className='label'>
              <span className='label-text'>Add your terms here.</span>
            </div>
            <label className='input input-bordered flex items-center gap-2'>
              <input value={oneterm} onChange={e => setOneTerm(e.target.value)} type='text' placeholder='Your terms (comma-seperated)' className=' w-full' />
              <IconPlus onClick={handleAddTerm} className='cursor-pointer' />
            </label>
          </div>

          <label className='form-control w-full my-4'>
            <div className='label'>
              <span className='label-text'>Use a file here. It should have each term on it's own line, and no header.</span>
            </div>
            <input onChange={handleFileChange} type='file' className='file-input file-input-bordered w-full' />
          </label>

          <form method='dialog' className='modal-action gap-2'>
            <button className='btn btn-sm btn-ghost'>Cancel</button>
            <button className='btn btn-sm btn-primary' onClick={handleOk} disabled={disabled}><IconPlus /> {disabled ? <span className='loading loading-spinner' /> : 'OK'}</button>
          </form>
        </div>
      </dialog>
      <button title={title} onClick={handleOpenClick} className={`btn ${className}`}><IconPlus /> {children}</button>
    </>
  )
}


import Select from '@/Select'

const abbr = {
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  af: 'Afrikaans',
  AL: 'Albania',
  am: 'Amharic',
  AM: 'Armenia',
  ar: 'Arabic',
  AU: 'Australia',
  AZ: 'Azerbaijan',
  az_AZ: 'Azerbaijani',
  BD: 'Bangladesh',
  be: 'Belarusian',
  BG: 'Bulgaria',
  bg: 'Bulgarian',
  bn_BD: 'Bangla',
  BE: 'Belgium',
  BR: 'Brazil',
  BY: 'Belarus',
  CA: 'Canada',
  ca: 'Catalan',
  CH: 'Switzerland',
  cs_CZ: 'Czech',
  CZ: 'Czechia',
  da_DK: 'Danish',
  DE: 'Germany',
  de_DE: 'German',
  de: 'German',
  DK: 'Denmark',
  EE: 'Estonia',
  el_GR: 'Greek',
  en_AU: 'English',
  en_CA: 'English',
  en_GB: 'English',
  en_IN: 'English',
  en_SG: 'English',
  en_US: 'English',
  en_ZA: 'English',
  ES: 'Spain',
  es_419: 'Spanish',
  es_ES: 'Spanish',
  es_US: 'Spanish',
  es_MX: 'Spanish',
  et: 'Estonian',
  ET: 'Ethiopia',
  eu_ES: 'Basque',
  fa: 'Persian',
  fa_AE: 'Persian',
  fa_AF: 'Persian',
  fa_IR: 'Persian',
  FI: 'Finland',
  fi_FI: 'Finnish',
  fil: 'Filipino',
  FR: 'France',
  fr_CA: 'French',
  fr: 'French',
  fr_FR: 'French',
  GB: 'United Kingdom',
  GE: 'Georgia',
  gl_ES: 'Galician',
  GR: 'Greece',
  gu: 'Gujarati',
  hi_IN: 'Hindi',
  HK: 'Hong Kong',
  HR: 'Croatia',
  hr: 'Croatian',
  HU: 'Hungary',
  hu_HU: 'Hungarian',
  hy_AM: 'Armenian',
  ID: 'Indonesia',
  id: 'Indonesian',
  IL: 'Israel',
  IN: 'India',
  IR: 'Iran',
  IS: 'Iceland',
  is_IS: 'Icelandic',
  IT: 'Italy',
  it_IT: 'Italian',
  iw_IL: 'Hebrew',
  ja_JP: 'Japanese',
  JP: 'Japan',
  ja: 'Japanese',
  ka_GE: 'Georgian',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  kk: 'Kazakh',
  km_KH: 'Khmer',
  kn_IN: 'Kannada',
  ko_KR: 'Korean',
  ko: 'Korean',
  KR: 'Korea',
  ky_KG: 'Kyrgyz',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LK: 'Sri Lanka',
  lo_LA: 'Lao',
  LT: 'Lithuania',
  lt: 'Lithuanian',
  LV: 'Latvia',
  lv: 'Latvian',
  MK: 'Macedonia',
  mk_MK: 'Macedonian',
  ml_IN: 'Malayalam',
  MM: 'Myanmar',
  MN: 'Mongolia',
  mn_MN: 'Mongolian',
  mr_IN: 'Marathi',
  ms: 'Malay',
  ms_MY: 'Malay',
  MX: 'Mexico',
  MY: 'Malaysia',
  my_MM: 'Burmese',
  ne_NP: 'Nepali',
  NL: 'Netherlands',
  nl_NL: 'Dutch',
  NO: 'Norway',
  no_NO: 'Norwegian',
  NP: 'Nepal',
  pa: 'Punjabi',
  PH: 'Philippines',
  PL: 'Poland',
  pl_PL: 'Polish',
  PT: 'Portugal',
  pt_BR: 'Portuguese',
  pt_PT: 'Portuguese',
  rm: 'Romansh',
  RO: 'Romania',
  ro: 'Romanian',
  RS: 'Serbia',
  RU: 'Russia',
  ru_RU: 'Russian',
  ru: 'Russian',
  SA: 'Saudi Arabia',
  SE: 'Sweden',
  SG: 'Singapore',
  SI: 'Slovenia',
  si_LK: 'Sinhala',
  sk: 'Slovak',
  SK: 'Slovakia',
  sl: 'Slovenian',
  sq: 'Albanian',
  sr: 'Serbian',
  sv_SE: 'Swedish',
  sw: 'Swahili',
  ta_IN: 'Tamil',
  te_IN: 'Telugu',
  th: 'Thai',
  TH: 'Thailand',
  TR: 'Turkey',
  tr_TR: 'Turkish',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  uk: 'Ukrainian',
  ur: 'Urdu',
  US: 'United States',
  vi: 'Vietnamese',
  VN: 'Vietnam',
  ZA: 'South Africa',
  zh_CN: 'Chinese (Simplified)',
  zh_Hant: 'Chinese (Simplified)',
  zh_HK: 'Chinese (Hong Kong)',
  zh_TW: 'Chinese (Traditional)',
  zu: 'Zulu'
}

const platformMap = {
  apple: {
    AU: ['en_AU'],
    BE: ['en_GB', 'fr'],
    CA: ['en_CA', 'fr_CA'],
    DE: ['de', 'en_GB'],
    FR: ['fr', 'en_GB'],
    GB: ['en_GB'],
    JP: ['ja', 'en_US'],
    KR: ['ko', 'en_GB'],
    MX: ['es_MX'],
    RU: ['ru', 'en_GB'],
    TW: ['zh_Hant', 'en_GB'],
    US: ['en_US', 'es_MX']
  },
  google: {
    AE: ['fa_AE'],
    AF: ['fa_AF'],
    AL: ['sq'],
    AM: ['hy_AM'],
    AU: ['en_AU'],
    AZ: ['az_AZ'],
    BD: ['bn_BD'],
    BG: ['bg'],
    BR: ['pt_BR'],
    BY: ['be'],
    CA: ['fr_CA'],
    CH: ['rm'],
    CZ: ['cs_CZ'],
    DE: ['de_DE'],
    DK: ['da_DK'],
    EE: ['et'],
    ES: ['es_ES'],
    ET: ['am'],
    FI: ['fi_FI'],
    FR: ['fr_FR'],
    GB: ['en_GB'],
    GE: ['ka_GE'],
    GR: ['el_GR'],
    HK: ['zh_HK'],
    HR: ['hr'],
    HU: ['hu_HU'],
    ID: ['id'],
    IL: ['iw_IL'],
    IN: ['ur'],
    IR: ['fa_IR'],
    IS: ['is_IS'],
    IT: ['it_IT'],
    JP: ['ja_JP'],
    KG: ['ky_KG'],
    KH: ['km_KH'],
    KR: ['ko_KR'],
    KZ: ['kk'],
    LA: ['lo_LA'],
    LK: ['si_LK'],
    LT: ['lt'],
    LV: ['lv'],
    MK: ['mk_MK'],
    MM: ['my_MM'],
    MN: ['mn_MN'],
    MX: ['es_419'],
    MY: ['ms_MY'],
    NL: ['nl_NL'],
    NO: ['no_NO'],
    NP: ['ne_NP'],
    PH: ['fil'],
    PL: ['pl_PL'],
    PT: ['pt_PT'],
    RO: ['ro'],
    RS: ['sr'],
    RU: ['ru_RU'],
    SA: ['ar'],
    SE: ['sv_SE'],
    SG: ['en_SG'],
    SI: ['sl'],
    SK: ['sk'],
    TH: ['th'],
    TR: ['tr_TR'],
    TW: ['zh_TW'],
    TZ: ['sw'],
    UA: ['uk'],
    US: ['en_US'],
    VN: ['vi'],
    ZA: ['zu']
  }
}

export default function WidgetLocale ({ value, platform, onChange }) {
  const map = platformMap[platform.toLowerCase()]

  const selCountry = Object.keys(map).reduce((acc, c) => {
    acc[c] = abbr[c]
    return acc
  }, {})

  const selLanguage = Object.keys(map).reduce((acc, c) => {
    acc[c] = map[c].reduce((acc, l) => {
      acc[l] = abbr[l]
      return acc
    }, {})
    return acc
  }, {})

  const handleLangChange = language => onChange({ ...value, language })
  const handleCountryChange = country => onChange({ country, language: map[country][0] })

  return (
    <div className='flex gap-4 w-full'>
      <div className='basis-1/2'>
        <label className='label '>
          Country
        </label>
        <Select
          label='Country'
          options={selCountry}
          value={value.country}
          onChange={handleCountryChange}
        />
      </div>

      <div className='flex-grow'>
        <label className='label'>
          Language
        </label>
        <Select
          label='Language'
          options={selLanguage[value.country] || {}}
          value={value.language}
          onChange={handleLangChange}
        />
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'

export const AUTH0_CLIENT_DOMAIN = 'gummicube.auth0.com'
export const AUTH0_CLIENT_ID = '5Qm2Mhy6pyLT7aRrLE5Dyg63FlT3ddhv'
export const GRAPHQL_SERVER = 'https://store-main.gummicube.workers.dev/'

// Hook that works simialr to useState, but persists in localStorage
export function useLocalStorage (k, initialValue) {
  const [storedValue, setStoredValue] = useState(initialValue)

  const storageListener = ({ key, newValue, oldValue, storageArea, url }) => {
    if (key === k) {
      setStoredValue(newValue)
    }
  }

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(k)
      setStoredValue(item ? JSON.parse(item) : initialValue)
    } catch (error) {
      console.log(error)
    }
    window.addEventListener('storage', storageListener)
    return () => window.removeEventListener('storage', storageListener)
  }, [])

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      window.localStorage.setItem(k, JSON.stringify(valueToStore))
      // manually trigger storage event
      window.dispatchEvent(new StorageEvent('storage', { key: k, oldValue: storedValue, newValue: valueToStore }))
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

// simple wrapper around localstorage user
export function useUser () {
  return useLocalStorage('user', false)
}

// donaload some text as a file
export function download (filename, data) {
  const blob = new Blob([data], { type: 'text/csv' })
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}

export const readFileAsText = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = e => resolve(e.target.result)
  reader.onerror = reject
  reader.readAsText(file)
})

export const api = {
  fetch: (url, options = { headers: {} }) => fetch(`/api${url}`, { ...options, headers: { ...options.headers, authorization: `Bearer ${JSON.parse(localStorage.token)}` } })
    .then(async r => {
      const j = await r.json()
      if (!r.ok || r.status !== 200) {
        throw new Error(j.error)
      }
      return j
    }),
  fetchText: (url, options = { headers: {} }) => fetch(`/api${url}`, { ...options, headers: { ...options.headers, authorization: `Bearer ${JSON.parse(localStorage.token)}` } })
    .then(async r => {
      const t = await r.text()
      if (!r.ok || r.status !== 200) {
        const j = JSON.parse(t)
        throw new Error(j.error)
      }
      return t
    }),
  get: (url) => api.fetch(url),
  delete: (url) => api.fetch(url, { method: 'DELETE' }),
  post: (url, body = {}) => api.fetch(url, { headers: { 'content-type': 'application/json' }, method: 'POST', body: JSON.stringify(body) })
}

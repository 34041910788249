const rAppleId = /id(\d+)/

export default function WidgetAppApple ({ value, onChange, ...props }) {
  const handleChange = e => {
    const val = e.target.value
    const m = val.match(rAppleId)
    if (m && m[1]) {
      onChange(m[1])
    } else {
      onChange(val)
    }
  }

  return <input type='text' onChange={handleChange} value={value} {...props} />
}

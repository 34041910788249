import { useState, useRef } from 'react'
import { IconPlus, IconExclamationCircle } from '@tabler/icons-react'

import { api } from '@/common'
import WidgetAppApple from '@/WidgetAppApple.jsx'
import WidgetLocale from '@/WidgetLocale.jsx'

export default function ButtonAppAdd ({ onSubmit, value = {}, className = '', children = 'Add New App' }) {
  const r = useRef()
  const [appID, setAppID] = useState(value.id || '')
  const [error, setError] = useState()
  const [locale, setLocale] = useState({ country: value.country || 'US', language: value.language || 'en_US' })
  const [disabled, setDisabled] = useState(false)

  const handleOk = e => {
    setDisabled(true)
    e.preventDefault()
    const newApp = { id: appID, ...locale }
    api.post('/app', newApp)
      .then(a => {
        r.current.close()
        setError(undefined)
        onSubmit(newApp)
        setDisabled(false)
      }).catch(e => {
        setError(e.message)
        setDisabled(false)
      })
  }

  const handleOpenClick = () => {
    setError('')
    setDisabled(false)
    r.current.showModal()
  }

  return (
    <>
      <dialog ref={r} className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Add Desktop App</h3>
          {error && (
            <div role='alert' className='alert alert-error flex flex-row my-2'>
              <IconExclamationCircle />
              <span>{error}</span>
            </div>
          )}
          <div className='py-2 flex gap-4 flex-col'>
            <WidgetAppApple value={appID} onChange={setAppID} autoFocus placeholder='Your Ios Desktop App ID/URL' className='input input-bordered input-primary w-full' />
            <WidgetLocale platform='APPLE' value={locale} onChange={setLocale} />
          </div>
          <form method='dialog' className='modal-action gap-2'>
            <button className='btn btn-sm btn-ghost'>Cancel</button>
            <button className='btn btn-sm btn-primary' onClick={handleOk} disabled={disabled}><IconPlus /> {disabled ? <span className='loading loading-spinner' /> : 'OK'}</button>
          </form>
        </div>
      </dialog>
      <button onClick={handleOpenClick} className={`btn ${className}`}><IconPlus /> {children}</button>
    </>
  )
}

import { useState, useEffect } from 'react'

export default function Select ({ options, value, onChange, useKey }) {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value
    setSelectedValue(selectedValue)
    onChange(selectedValue)
  }

  return (
    <div className='relative'>
      <select
        value={selectedValue}
        onChange={handleSelectChange}
        className='select select-bordered w-full'
      >
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={key}>
            {useKey ? key : value}
          </option>
        ))}
      </select>
    </div>
  )
}
